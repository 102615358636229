.checkout {
    display: flex;
    align-items: stretch;
    justify-content: center;
    flex: 1;
    &_form {
        .paymentWay {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 15px;
            gap: 10px;
            button {
                padding: 10px 15px;
                border-radius: 10px;
                background-color: transparent;
                border: 1px solid var(--orange);
                transition: 0.3s;
                &.active {
                    color: white;
                    background-color: var(--orange);
                }
            }
        }
        display: inline-flex;
        flex-direction: column;
        align-items: center;
        justify-content: stretch;
        &_group {
            margin: 10px 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            min-width: 300px;
            max-width: 300px;
            text-align: center;
            label {
                font-size: 20px;
                margin-bottom: 5px;
            }
            input,
            textarea {
                width: 100%;
                min-width: 100%;
                max-width: 100%;
                min-height: 40px;
                padding: 8px 16px;
                font-size: 16px;
                border: 1px solid var(--orange);
                border-radius: 8px;
            }

            p.error {
                font-size: 12px;
                color: red;
            }
        }
    }
    .checkbox {
        min-width: 300px;
        max-width: 300px;
        margin-bottom: 10px;
        label {
            display: flex;
            align-items: center;
        }
        input {
            display: none;
        }
        svg {
            cursor: pointer;
        }
        p {
            font-size: 14px;
            flex: 1;
            a {
                color: blue;
            }
        }
    }
    &_basket {
        width: 300px;
    }
}
