.footer {
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: var(--orange);
    color: var(--black);
    width: 100vw;
    .inner {
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 15px;
    }
    a {
        text-decoration: underline;
    }
}
