.food {
    &_ul {
        .delivery {
            font-size: 16px;
            margin: 10px 0;
        }
        display: inline-flex;
        flex-direction: column;
        overflow: auto;
        .actions {
            display: flex;
            align-items: center;
            gap: 10px;
            &_btn {
                font-size: 20px;
                background: none;
                width: 20px;
                height: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
    &_li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid;
        padding: 10px 0;
        margin-bottom: 10px;
        img {
            margin-right: 10px;
        }
        span {
            margin-right: 10px;
        }
    }
}
