.order {
    &_ul {
        flex: 1;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 10px;
    }
    &_li {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border: 1px solid;
        padding: 10px;
        &_flex {
            display: flex;
            flex-direction: column;
            gap: 10px;
        }
        height: 100%;

        & > span {
            margin: 5px 0;
        }
    }
    &_basket {
        display: flex;
        flex-direction: column;
        padding: 10px 0;
        border-top: 1px solid;
        border-bottom: 1px solid;
        & > span {
            margin: 5px 0;
        }
    }
    &_actions {
        button:first-child {
            margin-right: 10px;
        }
        margin-top: 10px;
        display: flex;
        width: 100%;
        justify-content: flex-end;
    }
}
