.card {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    img {
        object-fit: cover;
        width: 70%;
        aspect-ratio: 1/1;
    }
    h5 {
        margin: 10px 0 5px;
    }
    h6 {
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 10px;
    }
}

@media screen and (max-width: 850px) {
    .card {
        img {
            width: 60%;
        }
        h5 {
            font-size: 18px;
        }
        & > button {
            font-size: 10px !important;
            span {
                white-space: nowrap;
            }
        }
    }
}
