.payment {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 80px;
    text-align: center;
    img {
        width: 250px;
    }
    h4 {
        margin-bottom: 10px;
    }
    p {
        margin-bottom: 10px;
        width: 90%;
    }
    a {
        margin-bottom: 15px;
        &.btn {
            padding: 10px;
            border-radius: 5px;
            background-color: var(--orange);
            color: white;
        }
    }
}
