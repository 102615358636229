.lists {
    display: flex;
    justify-content: space-evenly;
    align-items: stretch;
    gap: 10px;
    padding: 10px;

    &_el {
        flex: 1;
        border: 1px solid;
        padding: 10px;
        gap: 10px;
        display: flex;
        flex-direction: column;
    }
}
