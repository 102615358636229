.menu {
    padding-top: 100px;
    padding-bottom: 100px;
    h3 {
        text-align: center;
        margin-bottom: 30px;
    }
    &_list {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 30px;
    }
}

.loading {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 400px;
    span {
        display: block;
        height: 60px;
        width: 60px;
        border-radius: 50%;
        border: 5px solid var(--orange);
        border-bottom: 5px solid transparent;
        animation: rotate 1s linear infinite;
    }
}
.address_buttons {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 25px;
    margin-bottom: 60px;
    button {
        width: 100%;
        background-color: transparent;
        padding: 10px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        &.active {
            border: 3px solid var(--orange);
        }
        img {
            width: 100%;
            aspect-ratio: 16/9;
            object-fit: cover;
        }
    }
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@media screen and (max-width: 850px) {
    .menu {
        padding-top: 80px;
        padding-bottom: 20px;
        &_list {
            grid-template-columns: repeat(3, 1fr);
        }
    }
    .address_buttons {
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
        margin-bottom: 30px;
    }
}

@media screen and (max-width: 550px) {
    .menu {
        &_list {
            grid-template-columns: repeat(2, 1fr);
        }
    }
}
